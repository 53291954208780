import {createElement, forwardRef} from 'react';

import Button from '../button';
import {buttonIconStyles} from '../button/styles';
import type {Icon} from '../../types';
import type {ButtonProps} from '../button';
import type {ButtonVariantProps} from '../button/styles';

export type IconButtonProps = Omit<ButtonProps, 'iconLeft' | 'iconRight' | 'children'> &
	ButtonVariantProps & {
		icon: Icon;
	};

const IconButton = forwardRef<HTMLElement, IconButtonProps>(({size, icon, ...props}, forwardedRef) => (
	<Button {...props} ref={forwardedRef} size={size}>
		{createElement(icon, {className: buttonIconStyles({size})})}
	</Button>
));

IconButton.displayName = 'IconButton';

export default IconButton;
