import {cva} from 'class-variance-authority';

import type {VariantProps} from 'class-variance-authority';

export const textButtonStyles = cva(
	'bg-transparent hover:bg-transparent p-0 min-h-fit outline-none [&&]:focus-visible:outline-none focus-visible:underline underline-offset-4',
	{
		variants: {
			size: {xs: 'text-xs', sm: 'text-sm', md: 'text-base', lg: 'text-lg'},
			color: {
				neutral: ['text-mauve11', 'hover:text-mauve10', 'focus-visible:outline-mauve8'],
				primary: ['text-violet11', 'hover:text-violet10', 'focus-visible:outline-violet8'],
				secondary: ['text-plum11', 'hover:text-plum10', 'focus-visible:outline-plum8'],
				success: ['text-green11', 'hover:text-green10', 'focus-visible:outline-green8'],
				warning: ['text-yellow11', 'hover:text-yellow10', 'focus-visible:outline-yellow8'],
				danger: ['text-red11', 'hover:text-red10', 'focus-visible:outline-red8'],
				info: ['text-blue11', 'hover:text-blue10', 'focus-visible:outline-blue8'],
			},
		},
		defaultVariants: {size: 'md', color: 'neutral'},
	},
);

export type TextButtonVariantProps = VariantProps<typeof textButtonStyles>;
