import {forwardRef} from 'react';
import * as SeparatorPrimitive from '@radix-ui/react-separator';
import {twMerge} from 'tailwind-merge';

import {separatorStyles} from './styles';
import type {SeparatorVariantProps} from './styles';

export type SeparatorProps = SeparatorPrimitive.SeparatorProps & SeparatorVariantProps;
const Separator = forwardRef<HTMLDivElement, SeparatorProps>(
	({color, orientation = 'horizontal', className, ...props}, forwardedRef) => (
		<SeparatorPrimitive.Root
			{...props}
			className={twMerge(separatorStyles({color, orientation}), className)}
			orientation={orientation}
			ref={forwardedRef}
		/>
	),
);

Separator.displayName = 'Separator';

export default Separator;

export * from './styles';
