import {cva} from 'class-variance-authority';

import type {VariantProps} from 'class-variance-authority';

export const anchorStyles = cva(
	[
		'font-light visited:text-violet11 text-violet11 underline-offset-4 rounded-sm',
		'hover:underline flex items-center gap-1 shrink-0',
		'outline-none focus-visible:outline-none focus-visible:underline',
	],
	{variants: {disabled: {true: 'pointer-events-none opacity-40'}}, defaultVariants: {disabled: false}},
);

export type AnchorVariantProps = VariantProps<typeof anchorStyles>;
