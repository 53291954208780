import {cva} from 'class-variance-authority';

import type {VariantProps} from 'class-variance-authority';

export const unstyledButtonStyles = cva(['relative select-none whitespace-nowrap shrink-0'], {
	variants: {
		disabled: {true: 'pointer-events-none'},
		loading: {true: 'pointer-events-none'},
	},
	defaultVariants: {
		disabled: false,
		loading: false,
	},
});

export const buttonStyles = cva(
	[
		'rounded-md font-medium transition-all duration-75 ease-in-out border-2 focus-visible:outline flex items-center justify-center',
	],
	{
		variants: {
			disabled: {true: 'first:[&>div]:opacity-40'},
			size: {
				xxs: 'text-[10px] px-1 py-0 h-[1.25rem]',
				xs: 'text-xs py-0.5 px-1.5 min-h-[1.5rem]',
				sm: 'text-sm py-1 px-2 min-h-[2rem]',
				md: 'text-base py-1.5 px-2.5 min-h-[2.5rem]',
				lg: 'text-lg py-2 px-3 min-h-[3rem]',
				xl: 'text-xl py-2.5 px-3.5 min-h-[3.5rem]',
			},
			color: {
				neutral: [
					'border-mauve7',
					'hover:border-mauve8',
					'focus-visible:outline-mauve8',
					'data-[state=on]:dark:bg-mauve7 data-[state=on]:bg-mauve9 data-[state=on]:text-mauve1 dark:data-[state=on]:text-mauve12',
				],
				primary: [
					'border-violet7',
					'hover:border-violet8',
					'focus-visible:outline-violet8',
					'data-[state=on]:dark:bg-violet7 data-[state=on]:bg-violet9 data-[state=on]:text-violet1 dark:data-[state=on]:text-violet12',
				],
				secondary: [
					'border-plum7',
					'hover:border-plum8',
					'focus-visible:outline-plum8',
					'data-[state=on]:dark:bg-plum7 data-[state=on]:bg-plum9 data-[state=on]:text-plum1 dark:data-[state=on]:text-plum12',
				],
				success: [
					'border-green7',
					'hover:border-green8',
					'focus-visible:outline-green8',
					'data-[state=on]:dark:bg-green7 data-[state=on]:bg-green9 data-[state=on]:text-green1 dark:data-[state=on]:text-green12',
				],
				warning: [
					'border-yellow7',
					'hover:border-yellow8',
					'focus-visible:outline-yellow8',
					'data-[state=on]:dark:bg-yellow7 data-[state=on]:bg-yellow9 data-[state=on]:text-yellow12 dark:data-[state=on]:text-yellow1',
				],
				danger: [
					'border-red7',
					'hover:border-red8',
					'focus-visible:outline-red8',
					'data-[state=on]:dark:bg-red7 data-[state=on]:bg-red9 data-[state=on]:text-red1 dark:data-[state=on]:text-red12',
				],
				info: [
					'border-blue7',
					'hover:border-blue8',
					'focus-visible:outline-blue8',
					'data-[state=on]:dark:bg-blue7 data-[state=on]:bg-blue9 data-[state=on]:text-blue1 dark:data-[state=on]:text-blue12',
				],
			},
			intent: {
				subtle: '',
				ghost: 'bg-transparent border-0 border-transparent outline-1 focus-visible:outline-1',
				outline: 'bg-transparent',
			},
			pressed: {true: 'scale-[97.5%] transform'},
			circular: {true: 'rounded-full'},
		},
		compoundVariants: [
			{intent: ['ghost', 'subtle', 'outline'], color: 'neutral', className: 'text-mauve11'},
			{intent: ['ghost', 'subtle', 'outline'], color: 'primary', className: 'text-violet11'},
			{intent: ['ghost', 'subtle', 'outline'], color: 'secondary', className: 'text-plum11'},
			{intent: ['ghost', 'subtle', 'outline'], color: 'success', className: 'text-green11'},
			{intent: ['ghost', 'subtle', 'outline'], color: 'warning', className: 'text-yellow11'},
			{intent: ['ghost', 'subtle', 'outline'], color: 'danger', className: 'text-red11'},
			{intent: ['ghost', 'subtle', 'outline'], color: 'info', className: 'text-blue11'},
			{intent: 'subtle', color: 'neutral', className: 'bg-mauve3'},
			{intent: 'subtle', color: 'primary', className: 'bg-violet3'},
			{intent: 'subtle', color: 'secondary', className: 'bg-plum3'},
			{intent: 'subtle', color: 'success', className: 'bg-green3'},
			{intent: 'subtle', color: 'warning', className: 'bg-yellow3'},
			{intent: 'subtle', color: 'danger', className: 'bg-red3'},
			{intent: 'subtle', color: 'info', className: 'bg-blue3'},
			{intent: 'subtle', color: 'neutral', pressed: false, className: 'bg-mauve3 hover:bg-mauve4'},
			{intent: 'subtle', color: 'primary', pressed: false, className: 'bg-violet3 hover:bg-violet4'},
			{intent: 'subtle', color: 'secondary', pressed: false, className: 'bg-plum3 hover:bg-plum4'},
			{intent: 'subtle', color: 'success', pressed: false, className: 'bg-green3 hover:bg-green4'},
			{intent: 'subtle', color: 'warning', pressed: false, className: 'bg-yellow3 hover:bg-yellow4'},
			{intent: 'subtle', color: 'danger', pressed: false, className: 'bg-red3 hover:bg-red4'},
			{intent: 'subtle', color: 'info', pressed: false, className: 'bg-blue3 hover:bg-blue4'},
			{intent: 'subtle', color: 'neutral', pressed: true, className: 'bg-mauve6'},
			{intent: 'subtle', color: 'primary', pressed: true, className: 'bg-violet6'},
			{intent: 'subtle', color: 'secondary', pressed: true, className: 'bg-plum6'},
			{intent: 'subtle', color: 'success', pressed: true, className: 'bg-green6'},
			{intent: 'subtle', color: 'warning', pressed: true, className: 'bg-yellow6'},
			{intent: 'subtle', color: 'danger', pressed: true, className: 'bg-red6'},
			{intent: 'subtle', color: 'info', pressed: true, className: 'bg-blue6'},
			{intent: 'ghost', color: 'neutral', className: 'hover:bg-mauve4'},
			{intent: 'ghost', color: 'primary', className: 'hover:bg-violet4'},
			{intent: 'ghost', color: 'secondary', className: 'hover:bg-plum4'},
			{intent: 'ghost', color: 'success', className: 'hover:bg-green4'},
			{intent: 'ghost', color: 'warning', className: 'hover:bg-yellow4'},
			{intent: 'ghost', color: 'danger', className: 'hover:bg-red4'},
			{intent: 'ghost', color: 'info', className: 'hover:bg-blue4'},
			{circular: true, size: 'xs', className: 'w-4 h-4 p-0 min-h-fit'},
			{circular: true, size: 'sm', className: 'w-6 h-6 p-0 min-h-fit'},
			{circular: true, size: 'md', className: 'w-8 h-8 p-0 min-h-fit'},
			{circular: true, size: 'lg', className: 'w-10 h-10 p-0 min-h-fit'},
			{circular: true, size: 'xl', className: 'w-12 h-12 p-0 min-h-fit'},
		],
		defaultVariants: {
			disabled: false,
			size: 'md',
			color: 'neutral',
			intent: 'subtle',
			pressed: false,
			circular: false,
		},
	},
);

export const buttonIconStyles = cva(undefined, {
	variants: {
		size: {xxs: 'w-2 h-2', xs: 'w-3 h-3', sm: 'w-4 h-4', md: 'w-5 h-5', lg: 'w-6 h-6', xl: 'w-7 h-7'},
	},
	defaultVariants: {size: 'md'},
});

export type ButtonVariantProps = VariantProps<typeof buttonStyles>;
export type ButtonIconVariantProps = VariantProps<typeof buttonIconStyles>;
