import {cva} from 'class-variance-authority';

import type {VariantProps} from 'class-variance-authority';

export const paperStyles = cva('rounded-sm outline-none focus-visible:outline-none flex flex-col', {
	variants: {
		padding: {none: 'p-0', xs: 'p-1', sm: 'p-2', md: 'p-4', lg: 'p-6', xl: 'p-8'},
		shadow: {true: 'shadow-lg', false: 'shadow-none'},
		bordered: {true: 'ring-1 ring-mauve6', false: 'ring-0'},
		accent: {
			neutral: 'border-mauve9',
			primary: 'border-violet9',
			secondary: 'border-plum9',
			success: 'border-green9',
			warning: 'border-yellow9',
			danger: 'border-red9',
			info: 'border-blue9',
		},
		accentPosition: {
			top: 'border-t-[6px]',
			right: 'border-r-[6px]',
			bottom: 'border-b-[6px]',
			left: 'border-l-[6px]',
			none: 'border-none',
		},
		inverted: {
			true: 'bg-mauve12 text-mauve2',
			false: 'bg-mauve2 text-mauve11',
		},
	},
	defaultVariants: {
		padding: 'md',
		shadow: false,
		bordered: false,
		accent: 'neutral',
		accentPosition: 'none',
		inverted: false,
	},
});

export type PaperVariantProps = VariantProps<typeof paperStyles>;
