import {forwardRef, useMemo} from 'react';
import {Primitive, type PrimitivePropsWithRef} from '@radix-ui/react-primitive';
import {mergeProps} from '@react-aria/utils';
import {m} from 'framer-motion';
import {twMerge} from 'tailwind-merge';

import type {MotionProps} from 'framer-motion';
import {paperStyles} from './styles';
import type {PaperVariantProps} from './styles';

export type PaperProps = PaperVariantProps &
	Omit<PrimitivePropsWithRef<'div'>, 'ref'> & {
		enableMotion?: boolean;
		motionOverrides?: MotionProps;
	};

const MotionComponent = m.create(Primitive.div);

const Paper = forwardRef<HTMLDivElement, PaperProps>(
	(
		{
			padding,
			shadow,
			bordered,
			accent,
			inverted,
			accentPosition,
			enableMotion,
			motionOverrides,
			className,
			...props
		},
		forwardedRef,
	) => {
		const motionProps = useMemo<MotionProps>(
			() =>
				enableMotion
					? {
							animate: {opacity: 1, transition: {ease: 'easeOut', duration: 0.15}},
							exit: {opacity: 0, transition: {ease: 'easeOut', duration: 0.15}},
							initial: {opacity: 0},
						}
					: {},
			[enableMotion],
		);

		return (
			<MotionComponent
				{...mergeProps(props, motionProps, motionOverrides)}
				className={twMerge(
					paperStyles({padding, shadow, bordered, accent, accentPosition, inverted}),
					className,
				)}
				ref={forwardedRef}
			/>
		);
	},
);

Paper.displayName = 'Paper';

export default Paper;

export * from './styles';
