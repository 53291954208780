import {cva} from 'class-variance-authority';

import type {VariantProps} from 'class-variance-authority';

export const separatorStyles = cva('select-none pointer-events-none', {
	variants: {
		color: {
			neutral: 'bg-mauve6',
			primary: 'bg-violet6',
			secondary: 'bg-plum6',
			success: 'bg-green6',
			warning: 'bg-yellow6',
			danger: 'bg-red6',
			info: 'bg-blue6',
		},
		orientation: {horizontal: 'h-px', vertical: 'w-px'},
	},
	defaultVariants: {color: 'neutral'},
});

export type SeparatorVariantProps = VariantProps<typeof separatorStyles>;
