import {cva} from 'class-variance-authority';

import type {VariantProps} from 'class-variance-authority';

export const loadingSpinnerStyles = cva('stroke-2', {
	variants: {
		size: {xxs: 'h-1 w-1', xs: 'h-2 w-2', sm: 'h-4 w-4', md: 'h-5 w-5', lg: 'h-7 w-7', xl: 'h-10 w-10'},
	},
	defaultVariants: {size: 'md'},
});

export type LoadingSpinnerVariantProps = VariantProps<typeof loadingSpinnerStyles>;
